import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const valueByString = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  s = s.replace(/^\./, '') // strip a leading dot
  var a = s.split('.')
  try {
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i]
      if (k in o) {
        o = o[k]
      } else {
        return
      }
    }
    return o
  } catch (e) {
    console.warn('object key not found')
    return ''
  }
}

export const formatValueByField = (field, data, intl) => {
  const value = valueByString(data, field.value)
  if (field.intlFormatNumber) {
    return intl.formatNumber(value, { format: field.intlFormatNumber })
  } else if (field.intlKey && field.intlValue) {
    const params = {}
    params[field.intlValue] = value
    return intl.formatMessage({ id: field.intlKey }, params)
  } else if (field.intlKey) {
    const id = `${field.intlKey}.${value}`
    const params = {}
    return intl.formatMessage({ id }, params)
  } else if (field.icons) {
    return <FontAwesomeIcon icon={field.icons[value]} />
  } else if (field.array) {
    return value.map(v => `${v}, `)
  }
  return value
}

export const energyClassColor = energyClass => {
  if (energyClass === 'gold') {
    return '#077643'
  } else if (energyClass === 'A') {
    return '#05a450'
  } else if (energyClass === 'B') {
    return '#4cb942'
  } else if (energyClass === 'C') {
    return '#ffda04'
  } else if (energyClass === 'D') {
    return '#f99420'
  } else if (energyClass === 'E') {
    return '#f47226'
  } else if (energyClass === 'F') {
    return '#ee192b'
  } else if (energyClass === 'G') {
    return '#b51f28'
  } else {
    return '#aaaaaa'
  }
}

export const calculateArea = (nettoArea, areas, garden) => {
  const area =
    nettoArea * 1.14 +
    areas.cellar.area * 0.35 +
    areas.hobby.area * 0.6 +
    areas.wintergarden.area * 0.5 +
    areas.balcony.area * 0.3 +
    // areas.coveredTerrace.area * 0.4 +
    areas.terrace.area * 0.3 +
    areas.underroof.area * 0.35
  const gardenArea = garden > 100 ? 100 * 0.1 + (garden - 100) * 0.05 : garden * 0.1
  return (area + gardenArea).toFixed(0)
}

export function debounce(func, wait, immediate) {
  var timeout
  return function() {
    var context = this,
      args = arguments
    var later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const oneOf = (string, array) => {
  return array.indexOf(string) >= 0
}

// Merge a `source` object to a `target` recursively
export const deepMerge = (target, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (let key of Object.keys(source)) {
    if (source[key] instanceof Object) Object.assign(source[key], deepMerge(target[key], source[key]))
  }

  // Join `target` and modified `source`
  Object.assign(target || {}, source)
  return target
}

export const timeout = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const energyClassValues = (isReadOnly, intl) => [
  {
    value: 'gold',
    width: isReadOnly ? '66%' : '32%',
    description: intl.formatMessage({ id: 'admin.property.energyClass.climateHouse' }),
    consumption: '≤ 10 kWh/m²'
  },
  {
    value: 'A',
    width: isReadOnly ? '68%' : '35%',
    description: intl.formatMessage({ id: 'admin.property.energyClass.climateHouse' }),
    consumption: '≤ 30 kWh/m²'
  },
  {
    value: 'B',
    width: isReadOnly ? '70%' : '38%',
    description: intl.formatMessage({ id: 'admin.property.energyClass.climateHouse' }),
    consumption: '≤ 50 kWh/m²'
  },
  {
    value: 'C',
    width: isReadOnly ? '72%' : '41%',
    description: intl.formatMessage({ id: 'admin.property.energyClass.minimalStandard' }),
    consumption: '≤ 70 kWh/m²'
  },
  {
    value: 'D',
    width: isReadOnly ? '74%' : '44%',
    description: intl.formatMessage({ id: 'admin.property.energyClass.existing' }),
    consumption: '≤ 90 kWh/m²'
  },
  {
    value: 'E',
    width: isReadOnly ? '76%' : '47%',
    description: intl.formatMessage({ id: 'admin.property.energyClass.existing' }),
    consumption: '≤ 120 kWh/m²'
  },
  {
    value: 'F',
    width: isReadOnly ? '78%' : '50%',
    description: intl.formatMessage({ id: 'admin.property.energyClass.existing' }),
    consumption: '≤ 160 kWh/m²'
  },
  {
    value: 'G',
    width: isReadOnly ? '80%' : '53%',
    description: intl.formatMessage({ id: 'admin.property.energyClass.existing' }),
    consumption: '> 160 kWh/m²'
  }
]

export const MATCH_ALERT_VARIANT = {
  not_interested: 'danger',
  wait: 'secondary',
  new: 'secondary',
  consulting: 'warning',
  visit: 'success'
}

export const arrayMove = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr
}
